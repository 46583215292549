import React, { memo, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
  Column,
  DropdownButtonProvider,
  DropdownLinkButton,
  DropdownList,
  DropdownListItem,
  Link,
  Row,
  Typography,
} from 'alloy-foundation';
import { PolicyReduced } from '../../../../models/PolicySummary';
import { StringColumn } from '../../../common/custom-datatable-columns/StringColumn';
import { SummaryPanel } from '../common/summaryPanel/SummaryPanel';
import { DataTableColumn } from '../../../data-table/DataTableColumn';
import { usePolicyData } from './usePolicyData';
import { DollarColumn } from '../../../common/custom-datatable-columns/DollarColumn';
import { useApplicationState } from '../../../common/providers/ApplicationStateProvider';
import { PolicySettings } from '../../../../models/ApplicationState/PolicyFilter';
import { useCommunicationService } from '../../../communicationService/CommunicationServiceProvider';
import { useVisible } from '../../../common/hooks/useVisible';
import InstanceSelection from '../../../quickNav/instanceSelection/InstanceSelection';
import { QuickNavAction } from '../../../../models/enums/QuickNavActions';
import { PssCustomer } from '../../../../models/PssCustomer';
import { convertSmartflowPolicyToPolicyReduced } from '../../../common/utils';
import { useFormatting } from '../../../common/hooks/useFormatting';
import { useLogFormProvider } from '../../../common/providers/LogFormProvider';

type SummaryParams = {
  id: string;
};

interface Props {
  policySettings: PolicySettings;
  customer: PssCustomer;
  savePolicySettings: (p: PolicySettings) => void;
}

const PoliciesGrid = ({ customer }: { customer: PssCustomer }) => {
  const { policySettings, setPolicySettings } = useApplicationState();
  return (
    <InnerPoliciesGrid
      policySettings={policySettings}
      savePolicySettings={setPolicySettings}
      customer={customer}
    />
  );
};

/** InnerPoliciesGrid is memoized to avoid unneccessary re-rendering when application state */
/** changes due to usage of useApplicationState() */
const InnerPoliciesGrid = memo(({ policySettings, savePolicySettings, customer }: Props) => {
  const { formatMessage } = useIntl();
  const { showForm } = useCommunicationService();
  const { formatDate } = useFormatting();
  const POLICY_CHANGE = 'PCH';
  const filterOptions = useMemo(
    () => [
      {
        label: formatMessage({ id: 'customer.policies.option.CurrentFuture' }),
        value: 'CurrentFuture',
      },
      {
        label: formatMessage({ id: 'customer.policies.option.Cancelled' }),
        value: 'Cancelled',
      },
      {
        label: formatMessage({ id: 'customer.policies.option.Lapsed' }),
        value: 'Lapsed',
      },
    ],

    [formatMessage]
  );

  const { id } = useParams<SummaryParams>();
  const [initialValue, updateInitialValues] = useState<PolicyReduced[]>([]);
  const [filteredValue, updateFilteredValues] = useState<PolicyReduced[]>([]);
  const { visible, SetVisible } = useVisible();
  const [quickNavAction, setQuickNavAction] = useState<QuickNavAction>(QuickNavAction.NewPolicy);
  const [selectedPolicy, setSelectedPolicy] = useState();

  const policies = usePolicyData(id, {
    onSuccess: (data) => {
      const updatedValues = [];
      data.Policies?.forEach((policy) =>
        updatedValues.push(convertSmartflowPolicyToPolicyReduced(policy))
      );
      updateInitialValues(updatedValues);
    },
  });

  const logForm = useLogFormProvider();

  const [initialFilter] = useState(
    filterOptions.find((p) => p.value === (policySettings?.filter ?? filterOptions[0].value)) ??
      null
  );

  const setInstanceSelectionVisible = (action: QuickNavAction, policy: any) => {
    setQuickNavAction(action);
    setSelectedPolicy(policy);
    SetVisible(true);
  };

  const actionCell = (row: PolicyReduced) => {
    // example usage - should open customer summary
    return (
      <>
        <DropdownButtonProvider>
          <DropdownLinkButton
            data-testid={`${row.Id}-ActionButtons`}
            aria-label={`${row.Id}-ActionButtons`}
          />
          <DropdownList>
            <DropdownListItem
              onSelect={() => setInstanceSelectionVisible(QuickNavAction.PolicyEndorsement, row)}
            >
              {formatMessage({ id: 'customer.policies.filter.Endorse' })}
            </DropdownListItem>
            <DropdownListItem
              onSelect={() => setInstanceSelectionVisible(QuickNavAction.PolicyRenewal, row)}
            >
              {formatMessage({ id: 'customer.policies.filter.Renew' })}
            </DropdownListItem>
            <DropdownListItem
              onSelect={() => setInstanceSelectionVisible(QuickNavAction.PolicyCancellation, row)}
            >
              {formatMessage({ id: 'customer.policies.filter.Cancel' })}
            </DropdownListItem>
            <DropdownListItem
              onSelect={() =>
                showForm('SIGRating', {
                  Action: 'Load',
                  Mode: 'PolicyTab',
                  CustId: row.CustId,
                  PolId: row.Id,
                  EndEffDate: row.EndEffDate,
                })
              }
            >
              {formatMessage({ id: 'customer.policies.filter.Rate' })}
            </DropdownListItem>
            <DropdownListItem
              onSelect={() => logForm.showLogForm('Activity', customer, row.Id, row.EndEffDate)}
            >
              {formatMessage({ id: 'customer.policies.filter.NewActivity' })}
            </DropdownListItem>
            <DropdownListItem
              onSelect={() => logForm.showLogForm('Suspense', customer, row.Id, row.EndEffDate)}
            >
              {formatMessage({ id: 'customer.policies.filter.NewSuspense' })}
            </DropdownListItem>
          </DropdownList>
        </DropdownButtonProvider>
      </>
    );
  };

  const columns: DataTableColumn<PolicyReduced>[] = [
    new StringColumn<PolicyReduced>({
      key: 'PolicyNumber',
      header: formatMessage({ id: 'customer.policies.Policy' }),
      flex: 2,
      getValue: (policy) => (policy ? `${policy.PolicyNumber},${policy.PolicyType}` : ''),
      Cell: function TermCell({ row: policy }) {
        return (
          <Row>
            <Column>
              {(policy.EffectiveDate ?? '') !== '' && (
                <Link
                  small
                  onClick={() =>
                    showForm('PolicyBrowse', { PolId: policy.Id, EndEffDate: policy.EndEffDate })
                  }
                  truncated={true}
                >
                  {policy.PolicyNumber}
                </Link>
              )}
              {(policy.ExpirationDate ?? '') !== '' && (
                <Typography variant="bodySm" noWrap={true}>
                  {policy.PolicyType}
                </Typography>
              )}
            </Column>
          </Row>
        );
      },
    }),
    new StringColumn<PolicyReduced>({
      key: 'EffectiveDate',
      header: formatMessage({ id: 'customer.policies.Term' }),
      flex: 2,
      getValue: (policy) => (policy ? `${policy.EffectiveDate},${policy.ExpirationDate}` : ''),
      Cell: function TermCell({ row: policy }) {
        let effectiveDate = '';
        if (policy.TransactionType === POLICY_CHANGE) effectiveDate = policy.EndEffDate;
        else effectiveDate = policy.EffectiveDate;
        return (
          <Row>
            <Column>
              {(effectiveDate ?? '') !== '' && (
                <Typography variant="bodySm">{formatDate(effectiveDate)}</Typography>
              )}
              {(policy.ExpirationDate ?? '') !== '' && (
                <Typography variant="bodySm" noWrap={true}>
                  {formatDate(policy.ExpirationDate)}
                </Typography>
              )}
            </Column>
          </Row>
        );
      },
    }),
    new StringColumn<PolicyReduced>({
      key: 'Status/TranType',
      header: formatMessage({ id: 'customer.policies.Status' }),
      flex: 2,
      getValue: (policy) => (policy ? `${policy.Status},${policy.TransactionType}` : ''),
      Cell: function TermCell({ row: policy }) {
        let displayTerm = '';
        if (policy.Status !== '' && policy.TransactionType !== '')
          displayTerm = `${policy.Status} / ${policy.TransactionType}`;
        return (
          <Row>
            <Column>
              {displayTerm !== '' && <Typography variant="bodySm">{displayTerm}</Typography>}
            </Column>
          </Row>
        );
      },
    }),
    new StringColumn<PolicyReduced>({
      key: 'ParentCompany',
      header: formatMessage({ id: 'customer.policies.Company' }),
      flex: 2,
      noWrap: true,
    }),
    new DollarColumn<PolicyReduced>({
      key: 'Premium',
      header: formatMessage({ id: 'customer.policies.Premium' }),
      dataTestId: 'premium-test',
    }),
    new StringColumn<PolicyReduced>({
      key: '',
      header: '',
      align: 'center',
      sortable: false,
      Cell: ({ row }) => actionCell(row),
    }),
  ];

  const handleFilterChange = (selection) => {
    savePolicySettings({ filter: selection ? selection.value : '' });
  };
  useEffect(() => {
    const filterChange = (value) => {
      return initialValue.filter((item) => {
        if (value === '') return item;
        return (
          item.Status.trim() === filterOptions.find((p) => p.value === value)?.label ??
          filterOptions[0].label
        );
      });
    };
    updateFilteredValues(filterChange(policySettings?.filter ?? filterOptions[0].value));
  }, [policySettings?.filter, initialValue, formatMessage, filterOptions]);

  return (
    <React.Fragment>
      {visible && (
        <InstanceSelection
          customer={customer}
          policy={selectedPolicy}
          onClose={() => SetVisible(false)}
          quickNavAction={quickNavAction}
        />
      )}
      <SummaryPanel
        title={formatMessage({ id: 'customer.policies.Header' })}
        id="SummaryActivity"
        columns={columns}
        data={filteredValue || []}
        isLoading={policies.isLoading}
        initialSortDirection="asc"
        initialSortedColumnKey="PolicyNumber"
        filtering={true}
        filterPlaceholder={formatMessage({ id: 'dashboard.panel.dateRangeFilter.placeholder' })}
        filterOptions={filterOptions}
        initialFilterOption={initialFilter}
        onFilterChange={handleFilterChange}
        rowSelection={false}
        responsive={false}
        emptyMessage={
          policies.isError ? (
            <Typography variant="disclaimer">
              <FormattedMessage id="common.error_loading_data" />
            </Typography>
          ) : (
            <Typography variant="disclaimer">
              <FormattedMessage id="dashboard.panel.data.empty" />
            </Typography>
          )
        }
        buttonText={formatMessage({ id: 'common.button.new' })}
        buttonOnClick={() => setInstanceSelectionVisible(QuickNavAction.NewPolicy, null)}
        toolTipText={formatMessage({ id: 'customer.policies.toolTipText' })}
      />
    </React.Fragment>
  );
});

export default PoliciesGrid;
